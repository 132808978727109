import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Where can I find out my voting locations?",
  "description": null,
  "author": "Diego",
  "categories": ["where", "faqs"],
  "date": "2020-08-02T00:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": ["when-is-my-next-election"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Go to the Georgia Government’s `}<a parentName="p" {...{
        "href": "https://www.mvp.sos.ga.gov/"
      }}>{`My Voter Page`}</a>{` to find out. Upon logging into your account, it will be under the `}<strong parentName="p">{`Polling Place for State, County, and Municipal Elections`}</strong></p>
    <p><img parentName="p" {...{
        "src": "/images/mvp-page.png",
        "alt": "Example screenshot"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      